if (!!window.app_data) {
  let uuid = null;
  try {
    const key = `u${window.app_data.user_id}-client_uuid`;
    uuid = localStorage.getItem(key);
    if (!uuid) {
      uuid = crypto.randomUUID();
      localStorage.setItem(key, uuid);
    }
  } catch (ex) {
  }
  window.app = {
    email: () => window.app_data.email,
    uid: () => window.app_data.user_id,
    uuid: () => uuid,
    latestClientTokens: () => window.app_data.latestClientTokens,
    latestClientUUIDs: () => window.app_data.latestClientUUIDs,
    syncServer: (token) => {
      try {
        const lastFetchTimeKey = `u${window.app_data.user_id}-last_server_sync_time`;
        const now = Date.now();
        const lastFetchTime = localStorage.getItem(lastFetchTimeKey);

        if (lastFetchTime && (now - lastFetchTime < 5 * 60 * 1000)) {
          // La fetch è stata eseguita meno di 5 minuti fa. Attendere prima di eseguire nuovamente.
          return;
        }
        localStorage.setItem(lastFetchTimeKey, now)

        fetch('/api/v1/clients/web', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: token,
            uuid: app.uuid(),
            uid: app.uid(),
            email: app.email(),
            agent: window.navigator.userAgent.substring(0, 255),
            fuid: false,
            notification: (("Notification" in window) && (Notification.permission !== 'default'))
              ? Notification.permission
              : null,
            version: window.app_data.version
          })
        })
          .then((resp) => resp.json())
          .then((data) => console.log(data))
          .catch((err) => console.error(err));
      } catch (err) {
        console.error(err);
      }
    }
  }
}
let registration = null;
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/sw.js').then(function (swRegistration) {
      registration = swRegistration;
    });
  });
}
if (("Notification" in window) && ("PushManager" in window) && (Notification.permission === "granted")) {
  import('./firebase-messaging.js');
} else {
  app.syncServer(null);
}
